// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataPeriod: null,
    respTemplateDetailData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PERIOD(state, payload) {
      state.respDataPeriod = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    ADD_PERIOD(state, payload) {
      if (state.respDataPeriod != null) {
        state.respDataPeriod.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respDataPeriod.max = state.respDataPeriod.max + 1
      } else {
        state.respDataPeriod.data = [payload]
        state.respDataPeriod.max = 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    UPDATE_PERIOD(state, payload) {
      const productIndex = state.respDataPeriod.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respDataPeriod.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    REMOVE_PERIOD(state, id) {
      if (state.respDataPeriod != null) {
        const ItemIndex = state.respDataPeriod.data.findIndex(p => p._id === id)
        state.respDataPeriod.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respDataPeriod.max = state.respDataPeriod.max - 1
      }
    },
    SWITCH_CHANGE(state, item) {
      const ItemIndex = state.respData.data.findIndex(p => p._id === item.id)
      if (ItemIndex > -1) {
        const nameChild = item.name.split('.')
        console.log(nameChild)
        if (nameChild.length === 3) {
          state.respData.data[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
        } else if (nameChild.length === 2) {
          state.respData.data[nameChild[0]][nameChild[1]] = item.status
        } else {
          state.respData.data[item.name] = item.status
        }
      }
    },
    GET_TEMPLATE_DETAIL(state, payload) {
      state.respTemplateDetailData = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/servicesTemplate/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/servicesTemplate/view?id=${item.id}`)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getPeriod({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/servicesTemplate/getPeriod?pageSize=${item.pageSize}&currentPage=${item.currentPage}&queueServiceId=${item.queueServiceId}&day=${item.day}`)
          .then(response => {
            commit('GET_PERIOD', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getMoreOption({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/servicesTemplate/getMoreOption?queueServiceId=${item.id}`)
          .then(response => {
            // commit('GET_PERIOD', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/servicesTemplate/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addPeriod({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/servicesTemplate/addPeriod', item)
          .then(response => {
            commit('ADD_PERIOD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/servicesTemplate/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateInView({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/servicesTemplate/updateInView', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updatePeriod({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/servicesTemplate/updatePeriod', item)
          .then(response => {
            commit('UPDATE_PERIOD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateMoreOption({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/hero/servicesTemplate/updateMoreOption?queueServiceId=${item.id}`, item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/servicesTemplate/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePeriod({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/servicesTemplate/deletePeriod?id=${item}`)
          .then(response => {
            commit('REMOVE_PERIOD', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTemplateDetail({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/servicesTemplate/getTemplateDetail')
          .then(response => {
            commit('GET_TEMPLATE_DETAIL', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
