<template>
  <b-card
    v-if="respData && $ability.can('read', this.$route.meta.resource)"
    class="blog-edit-wrapper"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-tabs>
        <b-tab
          v-for="(itemLang, indexLang) in language"
          :key="indexLang"
          :title="$t(itemLang.name)"
        >
          <b-form class="mt-2">
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                  class="mb-2"
                >
                  <b-form-input
                    v-if="respData.name[indexLang]"
                    :id="itemLang.name"
                    :value="respData.name[indexLang]['value']"
                    :name="itemLang.name"
                    :placeholder="$t(itemLang.name)"
                    @input="
                      inputLanguage($event, itemLang.lang, indexLang, 'name')
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('renderBy')"
                  label-for="register-renderBy"
                >
                  <v-select
                    id="register-renderBy"
                    v-model="respData.renderBy"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionRenderBy"
                    :reduce="(OptionRenderBy) => OptionRenderBy.value"
                    class="select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Detail')"
                  label-for="Detail"
                  class="mb-2"
                >
                  <b-form-input
                    v-if="respData.detail[indexLang]"
                    :id="itemLang.name"
                    :value="respData.detail[indexLang]['value']"
                    :name="itemLang.name"
                    :placeholder="$t(itemLang.name)"
                    @input="
                      inputLanguage($event, itemLang.lang, indexLang, 'detail')
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="respData.renderBy === 'html'"
                cols="12"
                class="mb-2"
              >
                <b-overlay
                  :show="loadingHtml"
                  rounded="sm"
                >
                  <b-form-group
                    label="Html"
                    label-for="blog-content"
                    class="mb-2"
                  >
                    <!-- <quill-editor
                id="discipline-qe"
                ref="quillEdit"
                v-model="respData.htmlData"
                :options="editorOption"
              /> -->
                    <b-form-file
                      id="getFile"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="uploadFunction($event)"
                    />
                  </b-form-group>
                  <b-form-textarea
                    v-if="respData.html[indexLang]"
                    id="textarea-state"
                    :value="respData.html[indexLang]['value']"
                    rows="8"
                    @input="
                      inputLanguage($event, itemLang.lang, indexLang, 'html')
                    "
                  />
                </b-overlay>
              </b-col>
              <b-col
                v-if="respData.renderBy === 'questions'"
                cols="12"
              >
                <b-form-group
                  :label="$t('Template Detail')"
                  label-for="register-partnerTemplateDetail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="partnerTemplateDetail"
                    vid="partnerTemplateDetail"
                    rules=""
                  >
                    <v-select
                      id="register-partnerTemplateDetail"
                      v-model="selectedTemplate"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="partnerTemplateDetailData"
                      :reduce="(partnerTemplateDetailData) => partnerTemplateDetailData"
                      class="select-size-lg"
                      @input="templateDetail($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col
                v-for="(item , index) in questions"
                :key="index"
                cols="12"
              >
                <b-row class="ml-1">
                  <p><b>{{ item.question }}</b></p>
                </b-row>
                <validation-provider
                  v-if="item.type == 'short_message' || item.type == 'link' || item.type == 'tel' || item.type == 'paragraph'"
                  #default="{ errors }"
                  :name="'question' + index"
                  :vid="'question' + index"
                  :rules="item.required ? 'required' : ''"
                >
                  <b-form-input
                    :id="'register-' + 'question' + index"
                    v-model="item.answer"
                    class="mb-1"
                    :name="'register-' + 'question' + index"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Answer')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div v-if="item.type == 'many_options'">
                  <b-col
                    v-for="(itemlist , i) in item.listOption"
                    :key="i"
                    cols="3"
                  >
                    <b-row>
                      <b-card-text class="ml-2 mr-2">
                        {{ itemlist.option }}
                      </b-card-text>
                      <b-form-checkbox
                        v-model="itemlist.select"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-row>
                  </b-col>
                </div>
              </b-col>

              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="saveChanges"
                >
                  {{ $t("Save Changes") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="cancelBtn"
                >
                  {{ $t("Cancel") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormTextarea,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import StoreModule from '@/store/services/hero/servicesTemplate'
// import StoreModule from '@/store/store-queue/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required } from '@validations'
// eslint-disable-next-line import/no-unresolved
import { ValidationProvider } from 'vee-validate'

const STORE_MODULE_NAME = 'services'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    ValidationProvider,
    BTabs,
    BTab,
    // quillEditor,
    // Editor,
    BFormTextarea,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataId: null,
      show: false,
      respData: {
        name: [],
        detail: [],
        renderBy: 'html',
        html: [],
      },
      loadingHtml: false,
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image', 'video'],
            ],
            handlers: {
              image() {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      OptionRenderBy: [
        { name: 'Flutter', value: 'flutter' },
        { name: 'Html', value: 'html' },
        { name: 'Question', value: 'questions' },
      ],
      questions: [],
      selectedTemplate: '',
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    partnerTemplateDetailData() {
      return store.state[STORE_MODULE_NAME].respTemplateDetailData != null ? store.state[STORE_MODULE_NAME].respTemplateDetailData.data : []
    },
  },
  created() {
    const Id = this.$route.params.id // subMenu
    this.dataId = Id
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getTemplateDetail`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    if (this.dataId !== 'add') {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.dataId })
        .then(result => {
          console.log('fetch Success : ', result)
          if (result.data.data) {
            // renderBy
            this.respData = result.data.data
            if (this.respData.renderBy === 'questions') {
              this.questions = this.respData.questions
              this.selectedTemplate = this.partnerTemplateDetailData.filter(res => res._id.toString() === this.respData.templateDetails.toString())
            }
            if (this.respData.html.length === 0) {
              if (this.respData.html.length !== this.language.length) {
                for (let index = 0; index < this.language.length; index += 1) {
                  const indexLanguage = this.respData.html.findIndex(element => element.lang === this.language[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.html.push({
                      lang: this.language[index].lang, // th , en
                      value: '',
                    })
                  }
                }
                for (let index = 0; index < this.respData.html.length; index += 1) {
                  const indexLanguage = this.language.findIndex(element => element.lang === this.respData.html[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.html.splice(index, 1)
                  }
                }
              }
            }
          }
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    templateDetail(data) {
      if (data == null) {
        this.questions = []
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < data.questions.length; index += 1) {
          const element = data.questions[index]
          const listOption = []
          for (let j = 0; j < element.listOption.length; j += 1) {
            const listOptionData = element.listOption[j]
            listOption.push({
              _id: listOptionData._id,
              option: listOptionData.option,
              select: false,
            })
          }
          this.questions.push({
            icon: element.icon,
            question: element.question,
            answer: '',
            type: element.type,
            listOption,
            required: element.required,
          })
        }
      }
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.respData.name[index].lang === lang) {
          this.respData.name[index].value = input
        }
      } else if (type === 'detail') {
        if (this.respData.detail[index].lang === lang) {
          this.respData.detail[index].value = input
        }
      } else if (type === 'html') {
        if (this.respData.html[index].lang === lang) {
          this.respData.html[index].value = input
        }
      }
    },
    uploadFunction(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }
        this.loadingHtml = true
        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = { image: e.target.result }
          this.$store
            .dispatch(`${STORE_MODULE_NAME}/uploadImage`, obj)
            .then(resp => {
              this.loadingHtml = false

              const range = this.$refs.quillEdit.quill.getSelection()
              this.$refs.quillEdit.quill.insertEmbed(
                range.index,
                'image',
                resp.data.url,
              )
            })
            .catch(err => {
              this.loadingHtml = false

              console.error(err)
            })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            console.log(this.questions)
            this.show = true
            const obj = {
              id: this.dataId,
              name: this.respData.name,
              detail: this.respData.detail,
              html: this.respData.html,
              renderBy: this.respData.renderBy,
              templateDetails: this.selectedTemplate._id,
              questions: this.questions,
            }
            if (this.dataId !== null && this.dataId !== 'add') {
              store
                .dispatch(`${STORE_MODULE_NAME}/updateInView`, obj)
                .then(result => {
                  this.show = false
                  console.log('fetch Success : ', result)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Success'),
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: this.$t('Success'),
                    },
                  })
                })
                .catch(error => {
                  this.show = false
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
    showHtml() {
      console.log(this.respData.htmlData)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.respData.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    inputChange(name) {
      const obj = {}
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

.ql-container {
  height: 250px;
}
</style>
